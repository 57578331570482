/* remove spin button on html5 input number */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

/* remove datalist triangle icon */
input::-webkit-calendar-picker-indicator {
  display: none;
}
