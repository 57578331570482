.react-datepicker-wrapper {
  display: unset;
  padding: unset;
  border: unset;
}

.react-datepicker__input-container {
  /* position: unset; */
  display: unset;
  width: unset;
}

.react-datepicker__week .react-datepicker__day.saturday {
  border-radius: 1em;
  border: solid 2px lightblue;
}

.react-datepicker__week .react-datepicker__day.holiday,
.react-datepicker__week .react-datepicker__day.sunday,
.react-datepicker__week .react-datepicker__day.saturday.holiday,
.react-datepicker__week .react-datepicker__day.sunday.holiday {
  border-radius: 1em;
  border: solid 2px lightpink;
}
