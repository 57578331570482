@media only screen and (max-width: 767.98px) {
  .my-breadcrumb .link:nth-last-child(n + 3) {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .my-breadcrumb .link:nth-last-child(n + 3) {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
}

@media only screen and (min-width: 1200px) {
}
