.ui.dropdown.multiple.withApi {
  border: solid 1px rgba(34, 36, 38, 0.15);
}

.ui.dropdown.multiple.withApi > a.ui.label {
  display: none;
}

.ui.dropdown.multiple.withApi > .ui.labels > .ui.label {
  margin-bottom: 0;
}
