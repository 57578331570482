.datatable.ui.sortable.table:not(.basic) thead th.sorted {
  background: #eff0f1;
}

@media only screen and (max-width: 767.98px) {
  .ui.datatable.sticky_table {
    width: 100% !important;
  }

  .ui.datatable.sticky_table thead tr {
    background-color: rgb(249, 250, 251);
  }

  .ui.stackable.menu .item .ui.pagination.menu .item {
    width: auto !important;
  }
}
